import * as React from "react"

const DevOpsProject = (props) => {
    return (
        <p><a href={props.project.url}>{props.project.name} ({props.project.visibility})</a></p>
    )
}

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const DevOpsProjectList = (props) => {
    console.log(props.projectList.value);

    return (
        <div id="projectList">
            { props.projectList.value.length > 0 ? props.projectList.value.map((project, i) => {
                return (
                    <DevOpsProject project={project} key={i} />
                )
            }) : null}
        </div>
    );
};
