import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const Seo = ({ description, lang, meta, siteTitle, title, social, contentType,
    articleCategory, articleAuthor, articlePublishedTime, articleModifiedTime, articleImage,
    firstName, lastName, gender, profileImage }) => {
  if (siteTitle == undefined) {
    siteTitle = process.env.siteTitle
  }

  var metaDetails = [
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: social?.twitter || ``,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
  ];

  var imageUrl = "/static/images/logo.png";

  switch (contentType) {
    case "article":
      imageUrl = articleImage

      metaDetails.push({
        name: `article:section`,
        content: articleCategory,
      })

      metaDetails.push({
        name: `article:author`,
        content: articleAuthor,
      })

      metaDetails.push({
        name: `article:published_time`,
        content: articlePublishedTime,
      })

      metaDetails.push({
        name: `article:modified_time`,
        content: articleModifiedTime,
      })
      
      break;

    case "profile":
      imageUrl = profileImage

      metaDetails.push({
        name: `profile:first_name`,
        content: firstName,
      })

      metaDetails.push({
        name: `profile:last_name`,
        content: lastName,
      })

      metaDetails.push({
        name: `profile:gender`,
        content: gender,
      })

      break;

    default:
      contentType = "website"
      break;
  }
  
  metaDetails.push({
    property: `og:type`,
    content: contentType,
  })

  metaDetails.push({
    property: `og:image`,
    content: imageUrl,
  })

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title ? `%s | ${siteTitle}` : null}
      meta={metaDetails.concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
