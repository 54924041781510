export function getRefreshToken(clientId) {
    let storageKey;
    let storage = window.sessionStorage;

    for (let i = 0; i < storage.length; i++) {if (storage.key(i).match(`refreshtoken-${clientId}--`)) {storageKey = storage.key(i)}}

    return JSON.parse(storage.getItem(storageKey)).secret;
}

export function getToken(refreshToken, clientId, tenantId, scope) {
    const uri = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`;

    const headers = new Headers();
    headers.append("content-type", "application/x-www-form-urlencoded;charset=utf-8");

    const body = `client_id=${clientId}&scope=${scope}&grant_type=refresh_token&client_info=1&refresh_token=${refreshToken}`;

    const options = {
        method: "POST",
        headers: headers,
        body: body
    };

    return fetch(uri, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export function getRequest(accessToken, uri) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(uri, options)
        .then(response => response.text())
        .catch(error => console.log(error));
}

export function getJSONRequest(accessToken, uri) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(uri, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export function postRequest(accessToken, uri, postBody) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Contet-Type", "application/json");

    const options = {
        method: "POST",
        headers: headers,
        body: postBody
    };

    return fetch(uri, options)
        .catch(error => console.log(error));
}
