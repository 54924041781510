import { adoConfig } from "../authConfig";
import { getToken, getJSONRequest } from "./commonApiCalls";

export async function getAdoToken(refreshToken, clientId, tenantId) {
    return getToken(refreshToken, clientId, tenantId, "499b84ac-1321-427f-aa17-267ca6975798%2Fuser_impersonation%20openid%20profile")
}

export async function getProjects(accessToken) {
    return getJSONRequest(accessToken, `${adoConfig.orgEndpoint}/_apis/projects?api-version=5.1`);
}

export async function getIterations(accessToken) {
    return getJSONRequest(accessToken, `${adoConfig.projectEndpoint}/_apis/work/teamsettings/iterations?&api-version=6.0`);
}
