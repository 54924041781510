import React, { useEffect, useState } from "react";
import { Link } from "gatsby"

import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { apiRequest } from "../authConfig";

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Loading } from "../components/loading";
import { ErrorComponent } from "../components/errorComponent";
import { DevOpsProjectList } from "../components/adoProjects";
import { getRefreshToken } from "../utils/commonApiCalls";
import { getAdoToken, getProjects } from "../utils/azureDevOpsApiCall";

import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const DevOpsContent = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
      if (account && !graphData && inProgress === InteractionStatus.None) {
          const login = {
              ...apiRequest,
              account: account
          };
          
          instance.acquireTokenSilent(login).then((loginResponse) => {
            window.localStorage.setItem("authClaims", JSON.stringify(loginResponse.account.idTokenClaims));

            // Get ADO Token
            const clientId = loginResponse.idTokenClaims.aud;
            let tenantId = loginResponse.tenantId;

            if (typeof(tenantId) === undefined || tenantId === '')
            {
                tenantId = 'c4afa44d-3294-4c39-b063-0593f7ae69d4' // process.env.authTenantId
            }

            let refreshToken = getRefreshToken(clientId);

            getAdoToken(refreshToken, clientId, tenantId).then((tokenResponse) => {
                let accessToken = tokenResponse.access_token;

                getProjects(accessToken).then(response => setGraphData(response));
            }).catch((e) => {
                console.log(`Failed to get ADO token: ${e}`);
            });
          }).catch((e) => {
              if (e instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenRedirect(login);
              }
          });
      }
  }, [account, inProgress, instance, graphData]);

  return (
      <Paper>
          { graphData ? <DevOpsProjectList projectList={graphData} /> : 
          <Skeleton variant="rectangular" height={200} style={{ width: "100%" }} /> }
      </Paper>
  );
};

const DevOpsPage = () => {
    const authRequest = {
        ...apiRequest
    };
  
    return (
        <Layout>
            <Container maxWidth="xl">
                <Box sx={{ my: 4 }}>
                    <Seo title="Azure DevOps" />
                    <Typography variant="h4" component="h1" gutterBottom>
                        Azure DevOps
                    </Typography>
                    <MsalAuthenticationTemplate 
                        interactionType={InteractionType.Redirect} 
                        authenticationRequest={authRequest} 
                        errorComponent={ErrorComponent} 
                        loadingComponent={Loading}
                    >
                        <DevOpsContent />
                    </MsalAuthenticationTemplate>
                    <Link to="/">Go back to the homepage</Link>
                </Box>
            </Container>
        </Layout>
    )
};

export default DevOpsPage
